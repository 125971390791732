import { Auth } from 'aws-amplify'

const awsconfig = {
  region: 'us-east-2',
  paymentHashUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/getpaymenthash',
  userPoolId: 'us-east-2_qfkE6ktMR',
  userPoolWebClientId: '6erat9idv8f6aejjgcaca8pk94',
  oauth: {
    domain: 'prod-pledgarden.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    redirectSignOut: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  },
  bucketUrl: 'https://pledge-garden-prod-upload-content.s3.amazonaws.com/',
  API: {
    endpoints: [
      {
        name: 'pg-api-v1',
        api_url: 'https://api.extensia.cloud',
        endpoint: 'https://api.extensia.cloud/v1',
        endpointv2: 'https://api.extensia.cloud/v2',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          idtoken: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
  stripe_publish_key:
    'pk_live_51K6dG5FiwAvE0g0SrkL99LIA7kCBphuPJ9keupk5syjk7xFoCLIT2ZKztSJ7qv1LCWnAbktbLj86LV5GgRzSxByg00xxYn5Dfb',
  stripe_connect_client_id: 'ca_KmF4WV7big6UOTbrKXhhg4qsaXm3qieE',
  paypal_client_id:
    'ATLu_8dj8gY3gJJCiaYqo9CazSFZJDL2sg7bhKYGPE4t0hkPgvwtoLVUps-rwrdYw0cubEU8ylTxs31s',
  paypal_bncode: 'PledgeGarden_SP_PPCP',
  reCaptchaKey: '',
  public_vapid_key:
    'BGHT_yJc1YI9fMpPVcDKITgRlOdlkQ1or2RZ7ObNKs5Rj9ph1KPGR6Klye31sTPBYk01GE5JZkqh-WcqKKuR-TQ',
  google_api_key: 'AIzaSyAu1COT1oscjAn-Jx8KyfAqwh2fNhrGXag',
  webapp_url: 'https://<org>.donate.extensia.cloud',
}

export { awsconfig }
